import React, { useCallback, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import orderBy from "lodash/orderBy";
import sortBy from "lodash/sortBy";

import Dialog from "@ui-kit/Dialog";
import Typography from "@ui-kit/Typography";
import { Spacer } from "@ui-kit/Spacer";
import Button from "@ui-kit/Button";

import { isAdmin } from "@selectors";
import { useUserStore } from "@store/UserStore";
import { RequiredDocumentsMap } from "@constants/required-documents";
import { BoolItem } from "@pages/DeepDivePanel/components/BoolItem";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";
import { useCss } from "./styles";
import { SuppliesFromHomeMap } from "@constants/supplies-from-home";
import { FirstDayChecklistMap } from "@constants/first-day-checklist";
import { HolidaysNamesMap } from "@constants/hollyday_schedule";
import { Models } from "@services/api";
import { getHolidaysList } from "@selectors/facility";

const ChecklistsAndDocuments: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [{ facility }] = useDeepDivePanelStore();
  const [{ user }] = useUserStore();
  const css = useCss();
  const holidaysList = getHolidaysList(facility);

  const closeDialog = useCallback(() => {
    history.replace({
      pathname: `/map/${facility.id}`,
      search,
    });
  }, [history, search]);

  return (
    <Dialog
      open
      onClose={closeDialog}
      data-test="checklists-and-documents-dialog"
      tier={facility.subscriptionTier}
      title="Checklists & documents"
    >
      <div css={css.root}>
        <Typography gutterBottom>
          <Typography variant="inherit" bolded>
            Parent handbook
          </Typography>{" "}
          {facility.documentsInfo?.parentHandbookFile.original ? (
            <a
              href={facility.documentsInfo?.parentHandbookFile.original}
              target="_blank"
              className="g-link"
              data-test="parentHandbookFile-link"
              rel="noreferrer"
            >
              click to view
            </a>
          ) : (
            <Typography variant="inherit" color="textSecondary">
              not available
            </Typography>
          )}
        </Typography>

        <Typography>
          <Typography variant="inherit" bolded>
            Enrollment application
          </Typography>{" "}
          {facility.documentsInfo?.enrollmentPacketFile.original ? (
            <a
              href={facility.documentsInfo?.enrollmentPacketFile.original}
              target="_blank"
              className="g-link"
              data-test="enrollmentPacketFile-link"
              rel="noreferrer"
            >
              click to view
            </a>
          ) : (
            <Typography variant="inherit" color="textSecondary">
              not available
            </Typography>
          )}
        </Typography>

        <Spacer size="medium" />

        <Typography variant="h5" bolded align="center" css={css.sectionTitle}>
          Enrollment documents
        </Typography>
        <Spacer size="small" />

        <Typography italic align="justify" gutterBottom>
          Required documents must be submitted before the first day of classroom
          attendance. These include, but may not be limited to:
        </Typography>
        {orderBy(
          facility?.documentsInfo?.requiredEnrollmentDocuments?.map(
            (d) => RequiredDocumentsMap[d],
          ),
          "order",
        )?.map((t, i) => (
          <Typography
            key={t.id}
            data-test="requiredEnrollmentDocuments-display"
            gutterBottom={
              !!facility.documentsInfo.requiredEnrollmentDocumentsDescription ||
              i !==
                facility.documentsInfo.requiredEnrollmentDocuments.length - 1
            }
          >
            • {t.name}
          </Typography>
        ))}

        {!!facility.documentsInfo.requiredEnrollmentDocumentsDescription && (
          <Typography italic align="justify">
            {facility.documentsInfo.requiredEnrollmentDocumentsDescription}
          </Typography>
        )}

        <Spacer size="medium" />

        <Typography variant="h5" bolded align="center" css={css.sectionTitle}>
          First day checklist
        </Typography>
        <Spacer size="small" />

        <Typography gutterBottom italic>
          In advance of your child's first day, please don't forget to:
        </Typography>
        {orderBy(
          facility?.documentsInfo?.firstDayChecklist?.map(
            (d) => FirstDayChecklistMap[d],
          ),
          "order",
        )?.map((t, i) => (
          <Typography
            key={t.id}
            data-test="firstDayChecklist-display"
            gutterBottom={
              !!facility.documentsInfo.firstDayChecklistDescription ||
              i !== facility.documentsInfo.firstDayChecklist.length - 1
            }
          >
            • {t.name}
          </Typography>
        ))}

        {!!facility.documentsInfo.firstDayChecklistDescription && (
          <Typography italic align="justify">
            {facility.documentsInfo.firstDayChecklistDescription}
          </Typography>
        )}

        {!!facility.documentsInfo?.suppliesFromHome?.length && (
          <Fragment>
            <Spacer size="medium" />
            <Typography
              variant="h5"
              bolded
              align="center"
              css={css.sectionTitle}
            >
              Supplies from home
            </Typography>
            <Spacer size="small" />

            <Typography gutterBottom italic>
              The following supplies are permitted to be brought from home:
            </Typography>
            {orderBy(
              facility?.documentsInfo?.suppliesFromHome?.map(
                (d) => SuppliesFromHomeMap[d],
              ),
              "order",
            )?.map((t, i) => (
              <Typography
                key={t.id}
                data-test="suppliesFromHome-display"
                gutterBottom={
                  !!facility.documentsInfo.suppliesFromHomeDescription ||
                  i !== facility.documentsInfo.suppliesFromHome.length - 1
                }
              >
                • {t.name}
              </Typography>
            ))}

            {!!facility.documentsInfo.suppliesFromHomeDescription && (
              <Typography italic align="justify">
                {facility.documentsInfo.suppliesFromHomeDescription}
              </Typography>
            )}
          </Fragment>
        )}

        <Spacer size="medium" />

        <Typography variant="h5" bolded align="center" css={css.sectionTitle}>
          Observed holidays
        </Typography>
        <Spacer size="small" />

        <BoolItem
          label="Public school calendar"
          state={facility.documentsInfo?.schoolCalendar}
        />

        <Typography paragraph>
          <Typography variant="inherit" bolded>
            School calendar:
          </Typography>{" "}
          {facility.documentsInfo?.schoolCalendarFile.original ? (
            <a
              href={facility.documentsInfo?.schoolCalendarFile.original}
              target="_blank"
              className="g-link"
              data-test="schoolCalendarFile-link"
              rel="noreferrer"
            >
              click to view
            </a>
          ) : (
            <Typography variant="inherit" color="textSecondary">
              not available
            </Typography>
          )}
        </Typography>

        {!!facility.documentsInfo?.holidaysDescription && (
          <Typography italic data-test="holidaysDescription-display">
            {facility.documentsInfo?.holidaysDescription}
          </Typography>
        )}

        {!!facility.documentsInfo?.holidays?.length &&
          !facility.documentsInfo.schoolCalendar && (
            <Fragment>
              <Spacer size="small" />
              {sortBy(facility?.documentsInfo.holidays, (h) => {
                return holidaysList.indexOf(h);
              }).map((h, i) => (
                <Typography
                  key={h}
                  data-test="documentsInfo-display"
                  gutterBottom={
                    i !== facility.documentsInfo.holidays.length - 1
                  }
                >
                  • {HolidaysNamesMap[h]}
                </Typography>
              ))}
            </Fragment>
          )}

        {(facility.isEditable || isAdmin(user)) && (
          <>
            <Spacer size="md" />
            <Button
              color={
                facility.subscriptionTier === Models.SubscriptionTier.Pro
                  ? "black"
                  : "primary"
              }
              variant="outlined"
              fullWidth
              size="large"
              link={`/map/${facility.id}/edit/documents`}
            >
              Edit checklists & documents
            </Button>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default ChecklistsAndDocuments;
